export class UrlConstant {
  public static readonly LOGIN = '/oauth/token';
  public static readonly LOGOUT = '/login/logout';
  public static readonly VALIDATE = '/login/validate';
  public static readonly LIST_USER = '/User';
  public static readonly LIST_ADMIN_EMPLOYEE = '/user/GetListAdminEmployees';
  public static readonly DELETE_USER = '/users/delete/';
  public static readonly ADD_USER = '/users';
  public static readonly ADD_USER_STAFF_VHL = '/user/CreateUserAccountStaffVHL';
  public static readonly DETAIL_USER = '/users';
  public static readonly UPDATE_USER = '/users/update';
  public static readonly UPDATE_USER_STAFF_VHL = '/user/UpdateUserAccountStaffVHL/';
  public static readonly LIST_ROLE = '/role';
  public static readonly LIST_GROUP = '/group';
  public static readonly LIST_GROUP_ALL_TITLE = '/group/getlistalltitle/';
  public static readonly LIST_ACTION = '/action';
  public static readonly LIST_MENU = '/menu';
  public static readonly LIST_STAFF = '/staff';
  public static readonly LIST_WORKROLE = '/workRole';

  public static readonly LIST_NOTIFY = '/Notification';
  public static readonly LIST_CUSTOMER_DEPOSIT_HISTORY = '/CustomerDepositHistories';
  public static readonly LIST_ACCOUNT_HISTORY = '/AccountDepositHistories';

  public static readonly DOWNLOAD_FILE = '/DownloadFile';

  /*Dinh duong*/
  public static readonly LIST_PHONG = '/department';
  public static readonly LIST_LOAIMON = '/loaimon';
  public static readonly LIST_TAIKHOAN = '/user';
  public static readonly LIST_PARTNERS = "/Partners";
  public static readonly LIST_ACCOUNTP = "/User";
  public static readonly LIST_MONAN = '/monan';
  public static readonly UPLOAD = '/Upload';
  public static readonly LIST_DONHANG = '/donHang';
  public static readonly LIST_NHOMTAIKHOAN = '/group';
  public static readonly LIST_KHO = '/warehouse';
  public static readonly LIST_DMTHIETBI = '/dmdevice';
  public static readonly LIST_THIETBI = '/device';
  public static readonly LIST_DM_HANGHOA = '/dmgoods';
  public static readonly LIST_HANGHOA = '/goods';
  public static readonly LIST_MINETURNS = '/mineturns';
  public static readonly LIST_TURNSSTATUS = '/turnsStatus';
  public static readonly LIST_TONKHO = '/warehouseGoods';

  public static readonly LIST_PHIEU_NHAPKHO = '/warehouseImport';
  public static readonly LIST_PHIEU_XUATKHO = '/warehouseExport';

  public static readonly LIST_MINETURNSSTAFF = '/mineturnsstaffs';
  public static readonly LIST_WAREHOUSE_FIRST_IMPORT = '/warehousefirstimport';

  public static readonly FILE = '/file';
  public static readonly LIST_CALAMVIEC = '/workshift';
  public static readonly LIST_VAITRO = '/workrole';


  // vhlBookingApi
  public static readonly BOOKING = '/booking';
  public static readonly BOOKINGV2 = '/BookingV2';
  public static readonly REQUEST_BOOKING = '/RequestBooking';
  public static readonly USER_REGISTER = '/UserRegister';

  public static readonly LIST_TEST_RESULT = "/ResultValues";

  // Hotel
  public static readonly HOTEL = "/Hotel";

  // Booking Hotel
  public static readonly BOOKING_HOTEL = "/BookingHotel";

  // Email
  public static readonly EMAIL = "/Email";

  // News
  public static readonly NEWS = "/News";


  // Utility
  public static readonly UTILITY = "/Utility";

  // Room
  public static readonly ROOM = "/Room";

  // Airport
  public static readonly AIRPORT = "/Airport";

  public static readonly ACCOUNT_DEPOSIT_HISTORIES = "/AccountDepositHistories";

}
