<!-- nzSubTitle="Khách hàng : {{requestBookingCurrent.userIdCreatedNavigation.fullname}}, số hiệu chuyến bay : {{requestBookingCurrent?.flightNumber ? requestBookingCurrent?.flightNumber : 'chưa xác định'}}, hành trình đi: {{requestBookingCurrent.tripItineraryDeparture}}, hãng hàng không : {{requestBookingCurrent.airlineName}}" -->

<nz-result *ngIf="isReservedSuccess == true" nzStatus="success" nzTitle="Giữ vé thành công!"
  nzSubTitle="{{ successMessenger }}">
  <!-- <div nz-result-extra>
    <button nz-button nzType="primary">Go Console</button>
    <button nz-button>Buy Again</button>
  </div> -->
</nz-result>

<nz-result *ngIf="isReservedSuccess == false" nzTitle="Giữ vé thất bại!" nzStatus="error" nzSubTitle="{{errorMessenger}}">
  <!-- <div nz-result-content>
    <div class="desc">
      <h4 nz-title>The content you submitted has the following error:</h4>
      <p nz-paragraph>
        <span nz-icon nzType="close-circle"></span>
        Your account has been frozen
        <a>Thaw immediately &gt;</a>
      </p>
      <p nz-paragraph>
        <span nz-icon nzType="close-circle"></span>
        Your account is not yet eligible to apply
        <a>Apply immediately &gt;</a>
      </p>
    </div>
  </div>
  <div nz-result-extra>
    <button nz-button nzType="primary">Go Console</button>
    <button nz-button>Buy Again</button>
  </div> -->
</nz-result>
