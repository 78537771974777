export enum NotificationType {
  RequestBooking = 0,//trang đặt vé yêu cầu
  BookingHotel = 1,//trang đặt khách sạn
  BusinessProfile = 2,//thông tin doanh nghiệp
  BusinessDetail = 3,//chi tiết doanh nghiệp
  ServiceRating = 4,//đánh giá dịch vụ
  AccountDepositHistory = 5,//Tab lịch sử nạp tiền tài khoản
  RatingBookingAirline = 6,//Trang đặt vé tự động
  RatingRequestBooking = 7,
  RatingBookingHotel = 8,
  Booking = 9,//Trang đặt vé khởi việt
  WhenCustomerRate = 10,
  WhenCustomerDeposit = 11, // Yêu cầu nạp tiền vào tài khoản
}
